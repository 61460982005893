<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8">
    <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
        <div class="px-4 sm:px-0">
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            Basic Information
          </h3>
          <p class="mt-1 text-sm text-gray-600">
            user can use his email and password to login to the platform.
          </p>
        </div>
      </div>
      <div class="mt-5 md:mt-0 md:col-span-2">
        <form method="POST" @submit="save">
          <div class="shadow sm:rounded-md sm:overflow-hidden">
            <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
              <div v-if="errorMessage" class="mb-4">
                <ErrorAlert :text="errorMessage" />
              </div>
              <div
                v-if="errors.length"
                class="mt-2 px-4 text-sm bg-red-50 text-red-800"
              >
                <ul class="list-disc p-4 space-y-1">
                  <li v-for="error in errors" :key="error">
                    {{ error }}
                  </li>
                </ul>
              </div>
              <div v-if="success" class="mb-4">
                <SuccessAlert text="User added successfully" />
              </div>

              <div class="grid grid-cols-3 gap-6">
                <div class="col-span-3 sm:col-span-2">
                  <LabeledInput
                    :value="name"
                    @updated="name = $event.target.value"
                    type="text"
                    label="Full Name"
                  />
                </div>
              </div>

              <div class="grid grid-cols-3 gap-6">
                <div class="col-span-3 sm:col-span-2">
                  <LabeledInput
                    :value="email"
                    @updated="email = $event.target.value"
                    type="email"
                    label="Email Address"
                  />
                </div>
              </div>

              <div class="grid grid-cols-3 gap-6">
                <div class="col-span-3 sm:col-span-2">
                  <LabeledInput
                    :value="phone"
                    @updated="phone = $event.target.value"
                    type="text"
                    label="Phone Number"
                  />
                </div>
              </div>

              <div class="grid grid-cols-3 gap-6">
                <div class="col-span-3 sm:col-span-2">
                  <LabeledInput
                    :value="password"
                    @updated="password = $event.target.value"
                    type="password"
                    label="Password"
                  />
                </div>
              </div>
            </div>
            <!-- <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                :disabled="adding"
                type="submit"
                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
              >
                {{ adding ? "wait" : "Save" }}
              </button>
            </div> -->
          </div>
        </form>
      </div>
    </div>

    <form method="POST" @submit="save">
      <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Permissions
            </h3>
            <p class="mt-1 text-sm text-gray-600">
              Permissions allow users to use specific features in the dashboard.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <div class="shadow sm:rounded-md sm:overflow-hidden">
            <div class="bg-white sm:p-6">
              <fieldset>
                <legend class="text-sm font-medium text-gray-900">
                  User permissions
                </legend>
                <div class="mt-2 space-y-5">
                  <div class="relative flex items-start">
                    <div class="absolute flex items-center h-5">
                      <input
                        id="admin"
                        name="permission"
                        aria-describedby="privacy-public-description"
                        type="radio"
                        class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                        "
                        value="admin"
                        v-model="permission"
                      />
                    </div>
                    <div class="pl-7 text-sm">
                      <label
                        for="privacy-public"
                        class="font-medium text-gray-900"
                      >
                        Admin
                      </label>
                      <p id="forward" class="text-gray-500">
                        All functions accessible.
                      </p>
                    </div>
                  </div>
                  <div class="relative flex items-start">
                    <div class="absolute flex items-center h-5">
                      <input
                        id="conference_manager"
                        name="permission"
                        aria-describedby="privacy-public-description"
                        type="radio"
                        class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                        "
                        value="conference_manager"
                        v-model="permission"
                      />
                    </div>
                    <div class="pl-7 text-sm">
                      <label for="voicemail" class="font-medium text-gray-900">
                        Conference Manager
                      </label>
                      <p id="privacy-public-description2" class="text-gray-500">
                        Can manage LIVE page, see recent call list, add
                        participants, listen and download recordings. Cannot
                        delete anything. Can view DIDs and voicemails, manage
                        the voicemail greeting, but not delete anything.
                      </p>
                    </div>
                  </div>
                  <div class="relative flex items-start">
                    <div class="absolute flex items-center h-5">
                      <input
                        id="listen_only"
                        name="permission"
                        aria-describedby="privacy-public-description"
                        type="radio"
                        class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                        "
                        value="listen_only"
                        v-model="permission"
                      />
                    </div>
                    <div class="pl-7 text-sm">
                      <label for="voicemail" class="font-medium text-gray-900">
                        Listen only
                      </label>
                      <p id="privacy-public-description3" class="text-gray-500">
                        Only able to view LIVE page, join via webrtc, but not
                        add calls or manage the conference in any way. They can
                        see participants and connected calls, but with censored
                        last 7 digits of dialed numbers). This account type
                        should be very limited in function
                      </p>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Additional Permissions
            </h3>
            <p class="mt-1 text-sm text-gray-600"></p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <div class="shadow sm:rounded-md sm:overflow-hidden">
            <div class="bg-white sm:p-6">
              <fieldset>
                <div class="mt-2 space-y-5">
                  <div class="relative flex items-start">
                    <div class="absolute flex items-center h-5">
                      <input
                        id="additional_permission"
                        aria-describedby="privacy-public-description"
                        type="checkbox"
                        :disabled="userType !== 'admin'"
                        class="
                          focus:ring-indigo-500
                          h-4
                          w-4
                          text-indigo-600
                          border-gray-300
                        "
                        true-value="1"
                        false-value="0"
                        v-model="additional_permission"
                      />
                    </div>
                    <div class="pl-7 text-sm">
                      <label
                        for="privacy-public"
                        class="font-medium text-gray-900"
                      >
                        Outreach
                      </label>
                      <p id="forward" class="text-gray-500">
                        Can access the outreach tab and send/receive messages.
                      </p>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <button
                :disabled="adding"
                type="submit"
                class="
                  inline-flex
                  justify-center
                  py-2
                  px-4
                  border border-transparent
                  shadow-sm
                  text-sm
                  font-medium
                  rounded-md
                  text-white
                  bg-gray-800
                  hover:bg-gray-900
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-gray-700
                "
              >
                {{ adding ? "wait" : "Save" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LabeledInput from "@/components/LabeledInput.vue";
import ErrorAlert from "@/components/ErrorAlert.vue";
import SuccessAlert from "@/components/SuccessAlert.vue";
import StorageUtil from "@/utils/LocalStorageUtil";

export default {
  components: {
    LabeledInput,
    ErrorAlert,
    SuccessAlert,
  },
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      password: "",
      permission: "conference_manager",
      additional_permission: "0",
      success: false,
      errors: [],
    };
  },
  watch: {
    permission(newValue) {
      if (newValue === "admin") this.additional_permission = "1";
      else this.additional_permission = "0";
    },
  },
  computed: {
    userType() {
      return StorageUtil.getUserType();
    },
    ...mapGetters("users", ["errorMessage", "adding"]),
  },
  methods: {
    async save(e) {
      e.preventDefault();
      console.log("additional_permission", this.additional_permission);
      if (!this.validate()) return;
      this.success = false;
      this.$Progress.start();
      await this.addUser({
        name: this.name,
        email: this.email,
        phone: this.phone,
        password: this.password,
        permission: this.permission,
        additional_permission: this.additional_permission,
      });
      this.$Progress.finish();
      if (this.errorMessage === null) {
        this.success = true;
        this.name = "";
        this.email = "";
        this.phone = "";
        this.password = "";
      }
    },
    validate() {
      this.errors = [];
      this.success = null;

      let isValid = true;
      if (this.name === "") {
        isValid = false;
        this.errors.push("Enter a valid name");
      }

      if (this.email === "") {
        isValid = false;
        this.errors.push("Enter a valid email");
      }

      if (this.phone === "") {
        isValid = false;
        this.errors.push("Enter a valid phone");
      }

      if (this.password === "" || this.password.length < 6) {
        isValid = false;
        this.errors.push("Enter a password with at least 6 characters");
      }

      return isValid;
    },
    ...mapActions("users", ["addUser"]),
  },
};
</script>

<style></style>
